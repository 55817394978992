import React from "react";

class Header extends React.Component {
  render() {
    return (
      <header className="header">
        <div className="l-center header-top">
          <hgroup className="brand">
            <h1 className="logo">
              <a href="/fr/">Bronchosedal</a>
            </h1>
            <h2 className="tagline">
              Marre de cette toux gênante ? BRONCHOSEDAL® vous soulage !
            </h2>
          </hgroup>
          <div className="row">
            <nav className="nav nav-main">
              <ol className="uilist">
                <li className="active first">
                  <a href="/fr/">Accueil</a>
                </li>
                <li>
                  <a href="/fr/la-toux/">La toux</a>
                </li>
                <li>
                  <a href="/fr/conseils-contre-la-toux/">
                    Conseils contre la toux
                  </a>
                </li>
                <li>
                  <a href="/fr/gamme-bronchosedal/">Gamme BRONCHOSEDAL®</a>
                </li>
                <li>
                  <a href="/fr/traiter/">Traiter</a>
                </li>
                <li className="last">
                  <a href="/fr/notices/">Notices</a>
                </li>
              </ol>
            </nav>
            <nav className="nav nav-mobile"></nav>
            <nav className="nav nav-lang">
              <ul className="uilist">
                <li id="uilist_nl">
                  <a href="/">nl</a>
                </li>
                <li id="uilist_fr" className="active">
                  <a href="/fr/">fr</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="slideshow-container">

          <div class="mySlides fade">
            <section className="banner">
              <div className="l-center media">
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/2_French.png"
                    alt
                  />
                </div>
                <div className="bd">
                  <hgroup>
                    <h3>Marre de cette toux gênante ?</h3>
                    <h4>BRONCHOSEDAL® vous soulage !</h4>
                  </hgroup>
                  <p>
                    La toux peut être très incommodante et parfois même douloureuse.
                <br />
                BRONCHOSEDAL® vous aide à combattre les symptômes
                <br />
                gênants d'une toux sèche ou d'une toux grasse.
              </p>
                  <p>
                    <a href="/fr/conseils-contre-la-toux/" className="btn btn-blue">
                      Toux gênante ?
                </a>{" "}
                    <a href="/fr/gamme-bronchosedal/" className="btn btn-green">
                      Gamme BRONCHOSEDAL®
                </a>
                  </p>
                </div>
              </div>
            </section>
          </div>

          <div class="mySlides fade carousel__slide2">
            <p class="banner-background"><a href="/fr/gamme-bronchosedal/"><img src="/assets/files/widgets/images/1_French.png" /></a></p>
          </div>

        </div>
        <br />

        <div class="dot-div">
          <span class="dot" fakeonclick="currentSlide(1)"></span>
          <span class="dot" fakeonclick="currentSlide(2)"></span>
        </div>
      </header>
    );
  }
}

export default Header;
